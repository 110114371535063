<template>
	<el-dialog
		title="报名须知"
		:visible.sync="dialogVisible"
		:modal-append-to-body="false"
		:lock-scroll="false"
		width="50%"
		@close="close"
		center
	>
		<div class="rules">
			<p>
				一、竞价保证金缴付方式及要求：<br />
				竞买人为个人的，可选择网上银行、微信、支付宝转账三种之一的方式缴付竞价保证金，不得采用该三种方式以外的其他方式缴付竞价保证金。<br />
				竞买人为企业的，可选择网上银行转账、微信、支付宝三种之一的方式缴付竞价保证金，不得采用该三种方式以外的其他方式缴付竞价保证金。<br />
				如果竞买人未按规定方式缴付竞价保证金，将会造成竞价保证金无法退还竞价人，由此产生的一切后果和责任，由竞买人自行承担。竞买人应妥善保管竞价保证金缴款凭证，该凭证将作为查询及办理相关手续的依据之一。<br />
				二、报名所需材料：<br />
				受让方为单位的，需提供合作社章程/公司章程（封面盖章，骑缝盖章）、合作社成员大会决议/股东会决议/董事会决议。<br />
				三、报名：<br />
				在项目详情界面点击“竞价报名”按钮后，并按要求缴纳保证金后，在个人中心界面完成保证金缴纳凭证上传，待平台审核通过后即报名成功，可以开展竞价。<br />
				四、项目报名时间截止后，仅有一名意向受让方报名的，直接与转出方协议签约；有两名及两名以上意向受让方报名的，实行网络电子竞价。<br />
				五、实行网上电子竞价的项目：竞价人在个人会员界面，点击“网络竞价大厅”按钮，在竞投报价截止时间之前进行报价。当报价时间距离竞价截止时间小于自动延时时，竞价截止时间将在报价时间基础上自动延长截止时间。竞价结束后，系统按竞投报价从高到低排序，选择报价最高者竞得项目。竞投报价不能低于项目挂牌价格，逾期报价或不符合规定的报价做无效处理。<br />
				六、受让方承诺在竞价成功后严格按照转出方的要求予以开发利用，且不用于非农业建设，不改变原用途，不破坏农业综合生产能力，不破坏生态环境，对于需提供开发利用规划的，及时提交规划文件。<br />
				七、当出现下列情形时，保证金不予退还，出让方可以受让方交纳的保证金为限，在扣除产权平台交易服务费用和经理人代理费用后，向受让方主张相应的赔偿责任：<br />
				（一）受让方故意提供虚假、失实材料造成出让方和产权平台损失的；<br />
				（二）受让方通过获取出让方商业秘密，侵害出让方合法权益的；<br />
				（三）受让方之间相互串通，影响公平竞争，侵害出让方合法权益的；<br />
				（四）受让方在竞价过程中违反竞价规则和产权平台交易相关规则，扰乱竞价秩序的；<br />
				（五）受让方恶意炒作、操纵市场价格、出价严重偏离市场价格等非正当手段扰乱市场交易行为的；<br />
				（六）受让方在竞价成功后未按约定支付合同价款和交易服务费的；<br />
				（七）受让方违反法律法规或相关规定给转让方造成损失的；<br />
				（八）受让方竞价成功后未在规定时间内签订合同，经催告后在合理期限内仍未签订合同的；<br />
				（九）受让方未按交易条件的要求完成项目交付交接的；<br />
				（十）产权平台认定的其他破坏正常交易秩序、不推进交易等违反公平、公正、诚实信用的行为。<br />
				保证金金额不足以弥补出让方、产权平台、经理人损失的，利益受损方可以向有过错的受让方继续进行追偿。<br />
				八、 出让方有权在用户报名竞价前自行决定撤回网络竞价项目。<br />
				九、 免责声明：<br />
				（一）平台对出让方或竞价人违反法律、法规或相关规定及违约行为不承担任何责任。<br />
				（二）若出现下列任何情况，平台不承担任何经济责任和法律责任：<br />
				1、对因不可抗力因素以及本所系统故障、设备故障、通讯故障、电力故障等突发事故所产生的不可预测或不可控制因素造成损失的；<br />
				2、网络竞价活动的时间以竞价系统服务器时间为准。由于竞价人自身终端设备时间与网络竞价系统服务器时间不符而导致未能按时参与报价的；<br />
				3、竞价人因自身原因导致其注册账户信息泄露而造成的一切后果；<br />
				4、竞价人因遗忘密码、自身终端设备出现故障或网络异常而不能正常参与竞价的；<br />
				5、竞价人不符合受让方条件或因自身原因未及时关注项目公告逾期报名没有取得竞价资格的；<br />
				6、其他因竞价人自身原因导致未能报名或正常参与交易的。<br />
				十、
				郑重提醒竞价人：报名前请仔细核对是否符合受让方条件并对竞价资格负责；平台发现不符合规定和相关条件的有权退回或取消资格；报名成功后产权平台会发送站内信息通知，在报名成功后，务必注意登陆账号和密码的安全。凡使用该账号登入平台后所进行的一切报价及所做出的一切行为，均被视为账号所属注册用户的自主有效行为，
				该注册用户应对行为后果承担全部经济与法律责任。<br />
				十一、
				竞价人应认真阅读并正确理解《农村产权交易服务网网络竞价实施办法（试行）》《农村产权交易服务网收费管理办法（试行）》《网络竞价项目承诺函》《网络竞价须知》《报名须知》等全部的条款及内容，竞价人一旦登录产权交易平台使用，即表示对上述全部条款及内容予以确认，无任何异议。<br />
			</p>
		</div>
		<div class="agree">
			<el-checkbox v-model="checked">我已阅读并同意以上条款</el-checkbox>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" style="width: 116px" @click="submit">确 定</el-button>
			<el-button type="primary" style="width: 116px" @click="close">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>

export default {
	data() {
		return {
			checked: false,
		}
	},
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		submit() {
			// 提交
			if (this.checked) {
				this.$emit('submit')
				this.$emit('close', false)
			} else {
				this.$message.info('请勾选我已查看并同意选项！')
			}
		},
		close() {
			// 关闭
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="less" scoped>
.rules {
	font-size: 15px;
	border-bottom: 1px solid rgb(92, 91, 91);
}
.agree {
	margin-top: 12px;
}
</style>
