<template>
  <!-- 项目详情 -->
  <div style="padding-top: 77px">
    <div class="project-detail" v-if="!isCj">
      <div class="base-info">
        <!-- <span>成交</span> -->
        <template>
          <div
            class="carousel"
            v-if="projectInfo.xctpList && projectInfo.xctpList.length > 0"
          >
            <swiper
              class="gallery-top"
              ref="swiperTop"
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="(item, index) in projectInfo.xctpList"
                :key="index"
              >
                <img :src="item.url" alt="" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

            <swiper
              class="gallery-thumbs"
              ref="swiperThumbs"
              :options="swiperOptionThumbs"
            >
              <swiper-slide
                v-for="(item, index) in projectInfo.xctpList"
                :key="index"
              >
                <img :src="item.url" alt="" />
              </swiper-slide>
            </swiper>
          </div>
          <div class="carousel" v-else>
            <img src="imgs/none.png" alt="" />
          </div>
        </template>
        <div class="detail">
          <div class="title">
            <span>{{ projectInfo.xmmc ? projectInfo.xmmc : "" }}</span>
          </div>
          <p>交易方式：{{ projectInfo.jyfs ? projectInfo.jyfs : "无" }}</p>
          <p>流转年限：{{ projectInfo.lznx ? projectInfo.lznx + "年" : "" }}</p>
          <p v-if="projectInfo.xmdz">详细地址：{{ projectInfo.xmdz }}</p>
          <p>发布时间：{{ projectInfo.fbsj ? projectInfo.fbsj : "" }}</p>
        </div>
        <div
          class="apply-status"
          v-if="
            access_token &&
            buttonStatus.type != null &&
            projectInfo.jyfs == '在线竞价交易'
          "
        >
          <el-button
            type="primary"
            v-if="buttonStatus.type == '0'"
            @click="joinProject"
            >{{ buttonStatus.detail ? buttonStatus.detail : "" }}</el-button
          >

          <el-button v-if="buttonStatus.type == '1'" :disabled="true">{{
            buttonStatus.detail ? buttonStatus.detail : ""
          }}</el-button>
          <el-button
            type="primary"
            style="width: 160px"
            v-if="buttonStatus.type == '2'"
            @click="uploadJfpz"
          >
            上传保证金缴费凭证
          </el-button>
          <el-button v-else-if="buttonStatus.type == '3'" :disabled="true">{{
            buttonStatus.detail
          }}</el-button>
          <el-button
            type="primary"
            v-if="buttonStatus.type == '4'"
            @click="bidding()"
            >{{ buttonStatus.detail }}</el-button
          >
          <el-button
            type="primary"
            v-if="buttonStatus.type == '5'"
            @click="uploadJfpz"
            >{{ buttonStatus.detail }}</el-button
          >

          <el-button
            :disabled="true"
            v-if="buttonStatus.type == '6'"
            @click="bidding()"
            >{{ buttonStatus.detail }}</el-button
          >
          <el-button
            :disabled="true"
            v-if="buttonStatus.type == '7'"
            @click="bidding()"
            >{{ buttonStatus.detail }}</el-button
          >
          <el-button
            :disabled="true"
            v-if="buttonStatus.type == '8'"
            @click="bidding()"
            >{{ buttonStatus.detail }}</el-button
          >
          <el-button type="primary" @click="shoucang()">收藏</el-button>
          <!-- <el-button @click="bidding">{{
            registration ? "竞价报名" : "不可竞价报名"
          }}</el-button> -->
        </div>
        <div v-if="projectInfo.jyfs == '在线磋商'" class="apply-status">
          <el-button type="primary" @click="baoming(5)">在线沟通</el-button>
          <el-button type="primary" @click="shoucang()">收藏</el-button>
        </div>
        
        <!-- <button @click="loo">按钮</button> -->

        <span class="goback" @click="goback">返回 ></span>
      </div>
      <div v-if="projectInfo.cqlxbh == '12' || projectInfo.cqlxbh == '13'">
        <!-- <span>成交2</span> -->
        <div class="table-con">
          <div class="table-title">
            <span>项目信息</span>
          </div>
          <div class="define-table">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">项目名称</td>
                <td class="value" :title="projectInfo.xmmc">
                  {{ projectInfo.xmmc }}
                </td>
                <td class="name">项目编号</td>
                <td class="value" :title="projectInfo.xmbh">
                  {{ projectInfo.xmbh }}
                </td>
              </tr>
              <tr>
                <td class="name">复垦耕地类型</td>
                <td class="value" :title="projectInfo.zctdyt">
                  {{ projectInfo.zctdyt }}
                </td>
                <td class="name">复垦耕地等级</td>
                <td class="value" :title="`${projectInfo.tdxz}`">
                  {{ projectInfo.tdxz }}
                </td>
              </tr>
              <tr>
                <td class="name">复垦耕地产能</td>
                <td class="value" :title="projectInfo.dsfzw">
                  {{ projectInfo.dsfzw }} 公斤
                </td>
                <td class="name">指标面积</td>
                <td class="value" :title="projectInfo.zcmj">
                  {{ projectInfo.zcmj }} 亩
                </td>
              </tr>
              <tr>
                <td class="name">指标证书</td>
                <td class="value" colspan="3" :title="projectInfo.cqz">
                  <el-button
                    v-if="projectInfo.cqz"
                    style="color: #3e57dd"
                    type="text"
                    @click="getImage(projectInfo.cqz, 'pdf')"
                    >指标证书.pdf</el-button
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="table-con">
          <div class="table-title">
            <span>出让信息</span>
          </div>
          <div class="define-table">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">产权类型</td>
                <td class="value" :title="type">{{ projectInfo.type }}</td>
                <td class="name">预期价格</td>
                <td class="value" :title="projectInfo.yqjg">
                  {{ projectInfo.yqjg }} 元/年/亩
                </td>
              </tr>
              <tr>
                <td class="name">交易方式</td>
                <td class="value" colspan="3" :title="projectInfo.jyfs">
                  {{ projectInfo.jyfs }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div v-else>
        <!-- <span>成交3</span> -->
        <div class="table-con">
          <div class="define-table" style="border-top: 4px solid #18d531">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">转出方</td>
                <td class="value" v-if="projectInfo.currentIdentity == 2 && !projectInfo.crf.match(/^(?=.*\d).*$/)">
                  {{
                    projectInfo.crf.substr(0, 1) +
                    (projectInfo.sex == 2 ? "女士" : "先生")
                  }}
                </td>
                <td class="value" v-else>{{ projectInfo.crf }}</td>
                <td class="name">联系方式</td>
                <td class="value">{{ projectInfo.lxfs }}</td>
              </tr>
              <tr>
                <td class="name">产权凭证</td>
                <td
                  class="value"
                  colspan="3"
                  v-if="
                    projectInfo.agree ||
                    projectInfo.identityId == identityObj.identityId
                  "
                >
                  <div
                    class="download-btn"
                    style="color: #2f9ee8"
                    v-for="(item, index) in projectInfo.cqzsList"
                    :key="index"
                    @click="download(item)"
                  >
                    {{ item.name }}
                  </div>
                </td>
                <td class="value" colspan="3" v-else>
                  <div>
                    <el-button type="primary" size="small" @click="baoming(6)"
                      >申请查看产权证书</el-button
                    >
                  </div>
                </td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易' &&  projectInfo.cqlxbh == '01'">
                <td class="name">地块规划条件审批结果</td>
                <td class="value">
                  <div
                    class="download-btn"
                    v-for="(item, index) in projectInfo.ghtjList"
                    :key="index"
                    @click="download(item)"
                  >
                    {{ item.name ? item.name : "" }}
                  </div>
                </td>
                <td class="name">地块评估价值结果</td>
                <td class="value">
                  <div
                    class="download-btn"
                    v-for="(item, index) in projectInfo.pgjzList"
                    :key="index"
                    @click="download(item)"
                  >
                    {{ item.name ? item.name : "" }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="table-con">
          <div class="table-title">
            <span>转出信息</span>
          </div>
          <div class="define-table">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">产权类型</td>
                <td class="value">{{ cqlx(projectInfo.cqlxbh)}}</td>
                <td class="name">是否属再流转</td>
                <td class="value" v-if="projectInfo.sfsyzlz != null">
                  {{ projectInfo.sfsyzlz == "0" ? "否" : "是" }}
                  <template v-if="projectInfo.sfsyzlz == '1'">
                    <span
                      class="download-btn"
                      v-for="(item, index) in projectInfo.zlzzmList"
                      :key="index"
                      @click="download(item)"
                    >
                      {{ "(" + item.name + ")" }}
                    </span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="name">挂牌底价</td>
                <td class="value">
                  {{
                    projectInfo.gpjg ? projectInfo.gpjg.toFixed(2) + "元" : ""
                  }}
                </td>
                <td class="name">付款方式</td>
                <td class="value">{{ projectInfo.fkfs }}</td>
              </tr>
              <tr>
                <td class="name">面积</td>
                <td class="value">
                  {{
                    projectInfo.zcmj +
                    (projectInfo.mjdw == "1" ? "亩" : "平方米")
                  }}
                </td>

                <td class="name">位置</td>
                <td class="value">{{ projectInfo.xmdz }}</td>
              </tr>
              <tr>
                <td class="name">流转方式</td>
                <td class="value">{{ lzlx(projectInfo.lzlx) }}</td>
                <td class="name">流转年限</td>
                <td class="value">
                  {{ projectInfo.lznx ? projectInfo.lznx + "年" : "" }}
                </td>
              </tr>
              <tr v-if="projectInfo.dz">
                <td class="value" colspan="2">
                  东至：{{ projectInfo.dz ? projectInfo.dz : "" }}
                </td>
                <td class="value" colspan="2">
                  南至：{{ projectInfo.nz ? projectInfo.nz : "" }}
                </td>
              </tr>
              <tr v-if="projectInfo.dz">
                <td class="value" colspan="2">
                  西至：{{ projectInfo.xz ? projectInfo.xz : "" }}
                </td>
                <td class="value" colspan="2">
                  北至：{{ projectInfo.bz ? projectInfo.bz : "" }}
                </td>
              </tr>
              <tr>
                <td class="name" v-if="projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01'">受让方条件</td>
                <td class="value" v-if="projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01'">面向已缴纳完意向保证金的社会人士</td>
                <td class="name">其他说明</td>
                <td class="value" :colspan="!(projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01') ? '3' :'1'">
                  {{ projectInfo.qtsm ? projectInfo.qtsm : "" }}
                </td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td class="name">是否缴纳保证金</td>
                <td class="value">
                  {{ projectInfo.sfjnbzj == "0" ? "否" : "是" }}
                </td>
                <td class="name">交易保证金(元)</td>
                <td class="valur">
                  {{ projectInfo.jybzj ? projectInfo.jybzj.toFixed(2) : "" }}
                </td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td colspan="4">
                  *保证金缴纳后，请尽快上传缴费凭证，上传后将在一个工作日内审核完成，审核通过后方可参与竞价
                  *保证金由县运营公司统一管理，竞价结束后，未中标用户保证金将在5个工作日内退回原账号，中标用户保证金将转至转出方账户
                </td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td class="name">缴纳形式</td>
                <td class="value">{{ projectInfo.jnxs }}</td>
                <td class="name">挂牌公告期</td>
                <td class="value">
                  {{
                    projectInfo.gpggts != null ? projectInfo.gpggts + "天" : ""
                  }}
                </td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td class="name">竞拍截止时间</td>
                <td class="value">{{ projectInfo.bmycsj }}</td>
                <td class="name">竞拍延时(分钟)</td>
                <td class="value">{{ projectInfo.jpys }}</td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td colspan="2" class="name">
                  挂牌期满后，如未征集到满足摘牌条件的意向受让方
                </td>
                <td colspan="2" class="name">信息发布终结</td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td rowspan="3">保证金划入账户</td>
                <td colspan="3">账户名称：{{ projectInfo.khm }}</td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td colspan="3">开户银行：{{ projectInfo.khh }}</td>
              </tr>
              <tr v-if="projectInfo.jyfs == '在线竞价交易'">
                <td colspan="3">账号：{{ projectInfo.account }}</td>
              </tr>

              <!-- projectInfo.lzlx -->
              <template
                v-if="
                  !(
                    (projectInfo.currentIdentity == '3' &&
                      projectInfo.cqlxbh == '01') ||
                    projectInfo.lzlx == 2
                  )
                "
              >
                <!-- <tr
                v-if="
                  !(
                    projectInfo.currentIdentity == '3' &&
                    projectInfo.cqlxbh == '01'
                  )
                "
              >
                <td colspan="4" class="name value">
                  <h4>补充交易详细约定</h4>
                </td>
              </tr> -->
                <tr
                  v-if="
                    !(
                      (projectInfo.currentIdentity == '3' &&
                        projectInfo.cqlxbh == '01') ||
                      !projectInfo.lzhdyt
                    )
                  "
                >
                  <td class="name">流转后用途</td>
                  <td colspan="3" class="value">
                    {{ projectInfo.lzhdyt }}
                  </td>
                </tr>
              </template>

              <template v-if="projectInfo.lzlx != 1">
                <tr
                  v-if="
                    !(
                      (projectInfo.currentIdentity == '3' &&
                        projectInfo.cqlxbh == '01') ||
                      !projectInfo.syzq
                    )
                  "
                >
                  <td class="name">租金或收益调整周期</td>
                  <td class="value">
                    每{{
                      projectInfo.syzq == null
                        ? "_"
                        : projectInfo.syzq == ""
                        ? "_"
                        : projectInfo.syzq
                    }}年调整一次租金
                  </td>
                  <td class="name">具体调整方式:</td>
                  <td class="value">
                    {{ projectInfo.tzfs }}
                  </td>
                </tr>
                <tr
                  v-if="
                    !(
                      (projectInfo.currentIdentity == '3' &&
                        projectInfo.cqlxbh == '01') ||
                      !projectInfo.zffs
                    )
                  "
                >
                  <td class="name">租金支付方式</td>
                  <td class="value">
                    {{
                      projectInfo.zffs == "1"
                        ? "一次性支付"
                        : projectInfo.zffs == "2"
                        ? "分期支付"
                        : projectInfo.zffs == null
                        ? ""
                        : ""
                    }}
                  </td>
                  <td colspan="2" class="value">
                    <p v-if="projectInfo.zffs == '2'">
                      <!-- (new Date(projectInfo.txrq).getMonth() + 1) +
                      "月" +
                      new Date(projectInfo.txrq).getDate() +
                      "日" -->
                      乙方须于每年{{ projectInfo.txrq }} 前支付({{
                        projectInfo.zfsj == "0"
                          ? "当"
                          : projectInfo.zfsj == "1"
                          ? "后"
                          : projectInfo.zfsj == null
                          ? ""
                          : ""
                      }})一年租金
                    </p>
                    <p v-if="projectInfo.zffs == '1'">
                      <!-- new Date(projectInfo.txrq).getFullYear() +
                      "年" +
                      (new Date(projectInfo.txrq).getMonth() + 1) +
                      "月" +
                      new Date(projectInfo.txrq).getDate() +
                      "日" -->
                      乙方须于{{ projectInfo.txrq }}前支付租金。
                    </p>
                  </td>
                </tr>
              </template>

              <template v-if="projectInfo.lzlx == 1">
                <tr v-if="projectInfo.gffhbz">
                  <td class="name">股份分红标准</td>
                  <td class="value">
                    {{
                      projectInfo.gffhbz == "1"
                        ? "按股分红"
                        : projectInfo.gffhbz == "2"
                        ? "保底收益+按股分红"
                        : projectInfo.gffhbz == null
                        ? ""
                        : ""
                    }}
                    <!-- {{projectInfo.gffhbz }}保底收益+按股分红/ -->
                  </td>
                  <td colspan="2" style="text-align: left" class="value">
                    <p v-if="projectInfo.gffhbz == 2">
                      保底收益每亩每年{{ projectInfo.bdsyqs }}元，每{{
                        projectInfo.bdsyns
                      }}年调整一次保底收益。<br /><br />

                      具体调整方式：{{ projectInfo.bdsytzfs }}
                    </p>
                    <p v-if="!projectInfo.gffhbz == 0">
                      根据
                      {{
                        projectInfo.fhgjlx == "0"
                          ? "出资额"
                          : projectInfo.fhgjlx == "1"
                          ? "股份数"
                          : projectInfo.fhgjlx == "2"
                          ? `其他：${projectInfo.fhgjxq}`
                          : ""
                      }}

                      分配盈余或者利润
                    </p>
                  </td>
                </tr>
                <tr v-if="projectInfo.gffhbz">
                  <td class="name">股份分红支付</td>
                  <td colspan="3" style="text-align: left" class="value">
                    <p v-if="projectInfo.gffhbz == 2">
                      <!-- (new Date(projectInfo.bdsyrq).getMonth() + 1) +
                      "月" +
                      new Date(projectInfo.bdsyrq).getDate() +
                      "日" -->

                      乙方须于每年{{ projectInfo.bdsyrq }}前支付(
                      {{
                        projectInfo.bdsysj == "0"
                          ? "当"
                          : projectInfo.bdsysj == "1"
                          ? "后一"
                          : projectInfo.bdsysj == null
                          ? ""
                          : ""
                      }}
                      )年保底收益。
                    </p>
                    <p v-if="projectInfo.gffhbz != 0">
                      乙方须于每年
                      <!-- (new Date(projectInfo.fhrq).getMonth() + 1) +
                      "月" +
                      new Date(projectInfo.fhrq).getDate() +
                      "日" -->
                      {{ projectInfo.fhrq }}前分配(
                      {{
                        projectInfo.fhsj == "0"
                          ? "前一"
                          : projectInfo.fhsj == "1"
                          ? "当"
                          : projectInfo.fhsj == null
                          ? ""
                          : ""
                      }}
                      )年盈余或者利润。
                    </p>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="project-detail" v-else>
      <div class="table-con" style="margin-top: 0">
        <div
          class="table-title"
          style="border-top: 1px solid #c9c9c9; font-weight: 550"
        >
          <span>{{ projectInfo.xmmc }}经营流转成交公示</span>
        </div>
        <div class="define-table">
          <table style="table-layout: fixed">
            <tr>
              <td class="name">项目名称</td>
              <td class="value">{{ projectInfo.xmmc }}</td>
              <td class="name">项目编号</td>
              <td class="value">{{ projectInfo.xmbh }}</td>
            </tr>
            <tr>
              <td class="name">流转方式</td>
              <td class="value">{{ lzlx(projectInfo.lzlx) }}</td>
              <td class="name">交易方式</td>
              <td class="value">在线竞价</td>
            </tr>
            <tr>
              <td class="name">挂牌单价</td>
              <td class="value">
                {{
                  projectInfo.gpdj
                    ? projectInfo.gpdj.toFixed(2) +
                      (projectInfo.mjdw == "1" ? "元/年/亩" : "元/年/平方米")
                    : ""
                }}
              </td>
              <td class="name">成交时间</td>
              <td class="value">{{ projectInfo.cjTime }}</td>
            </tr>
            <tr>
              <td class="name">项目所在区域</td>
              <td class="value" colspan="3">{{ projectInfo.xmdz }}</td>
            </tr>
            <tr>
              <td class="name">出让方</td>
              <td class="value" colspan="3">{{ projectInfo.crf }}</td>
            </tr>
            <tr>
              <td class="name">受让方</td>
              <td class="value" colspan="3">{{ projectInfo.srf }}</td>
            </tr>
            <tr>
              <td class="name">转出面积/数量</td>
              <td class="value" colspan="3">
                {{
                  projectInfo.zcmj + (projectInfo.mjdw == "1" ? "亩" : "平方米")
                }}
              </td>
            </tr>
            <tr>
              <td class="name">流转年限</td>
              <td class="value" colspan="3">
                {{ projectInfo.lznx ? projectInfo.lznx + "年" : "" }}
              </td>
            </tr>
            <tr>
              <td class="name">成交单价</td>
              <td class="value">
                {{
                  projectInfo.cjdj
                    ? projectInfo.cjdj.toFixed(2) +
                      (projectInfo.mjdw == "1" ? "元/年/亩" : "元/年/平方米")
                    : ""
                }}
              </td>
              <td class="name">成交总价</td>
              <td class="valur">
                {{ projectInfo.cjzj ? projectInfo.cjzj.toFixed(2) + "元" : "" }}
              </td>
            </tr>
            <tr>
              <td class="name">其他说明</td>
              <td class="value" colspan="3">{{ projectInfo.qtsm }}</td>
            </tr>
            <tr>
              <td class="name">监督方</td>
              <td class="value">{{ projectInfo.jdfmc }}</td>
              <td class="name">监督电话</td>
              <td class="value">{{ projectInfo.jdflxfs }}</td>
            </tr>
          </table>
        </div>
        <div class="table-bottom">
          <span
            >*请出让方、受让方在竞价完成后，依照相关规定签订合同，如未按时签约，相关法律风险由双方自行承担。</span
          >
          <el-button @click="goback">返回</el-button>
        </div>
      </div>
    </div>

    <join-rules
      :dialogVisible="dialogVisible"
      @close="dialogClose"
      @submit="submit"
    ></join-rules>
    <bidding-dialog
      v-if="biddingVisible"
      :dialogVisible.sync="biddingVisible"
      :data="currentBid"
      @close="
        () => {
          biddingVisible = false;
        }
      "
      @submit="submitBidding"
    ></bidding-dialog>
    <el-dialog
      title="上传缴费凭证"
      :visible.sync="visible"
      :modal-append-to-body="false"
      :lock-scroll="false"
      width="26%"
      center
    >
      <span style="color: red; display: inline-block; margin-bottom: 20px"
        >(支持：JPG/JPEG/GIF/PNG格式。)</span
      >
      <el-upload
        :class="{ disUoloadSty: noneBtnImg }"
        ref="pic1"
        multiple
        action=""
        name="files"
        :limit="1"
        :file-list="fileList1"
        list-type="picture-card"
        :before-upload="beforeUpload"
        :on-change="upload"
        :on-remove="removeXczp"
        :auto-upload="false"
      >
        <i
          slot="default"
          v-show="fileList1.length == 0"
          title="选取文件"
          class="el-icon-plus"
        ></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="width: 116px"
          :disabled="picLoading"
          :loading="picLoading"
          @click="submitPz"
          >确 定</el-button
        >
        <el-button
          type="primary"
          style="width: 116px"
          @click="
            () => {
              visible = false;
            }
          "
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getImageUrl,
  getPropertyType,
  getButtonStatus,
  getXmxxDetail,
  savePzxx,
  saveBidding,
  collectionXMXX
} from "../../api/exchange";
import { getStore } from "../../js/utils/store";
import joinRules from "../projectManagement/components/joinRules";
import biddingDialog from "../projectManagement/components/biddingDialog.vue";
import { checkJoinPro, saveJjbm } from "../../api/projectManage";
import { mapGetters } from "vuex";
import { getCertificateStatus } from "../../api/account";
import { uploadFile } from "@/api/fileApi";
import { getLzlx, getCqlx } from "../../util/types";
import { download } from "../../api/add";
import { SendMessage } from "../../api/websocket";
import { log } from "console";
export default {
  components: {
    joinRules,
    biddingDialog,
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //循环
        loop: true,
        loopedSlides: 6,
        spaceBetween: 10,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //滑动速度
        speed: 800,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        freeMode: true,
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 6, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: false,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      projectInfo: {
        xmbh: "",
      },
      buttonStatus: {},
      dialogVisible: false,
      visible: false,
      biddingVisible: false,
      pzprojectInfo: {},
      fileList1: [],
      landPics: [],
      proTypes: [],
      type: "",
      status: 0,
      imgSrc: "",
      landInfo: [],
      total: 10,
      currentPage: 1,
      pageSize: 10,
      isJoin: false,
      isCj: false,
      noneBtnImg: false,
      picLoading: false,
      registration: false,
    };
  },
  created() {
    this.check();
    this.initData();
    let token = getStore({ name: "access_token" });
    if (token) {
      this.getButtonStatus();

      this.registration = true;
    }
    if (this.$route.query.isCj) {
      this.isCj = this.$route.query.isCj;
    }
  },
  beforeDestroy() {
    this.isCj = false;
  },
  mounted() {
    if (this.projectInfo.tp) {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper;
        const swiperThumbs = this.$refs.swiperThumbs.$swiper;
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      });
    }
    this.getButtonStatus();
  },
  computed: mapGetters(["userInfo", "access_token", "identityObj"]),
  methods: {
   async shoucang(){
    const res = await collectionXMXX({
          xmbh: this.projectInfo.xmbh
        })
        if(res.data){
            this.$message({
            message: res.msg,
            type: "success",
          });
        }else{
          this.$message.error(res.msg);
        }
      
        console.log(res);
    },
    loo() {
      console.log(JSON.parse(JSON.stringify(this.projectInfo)));
    },
    // 在线竞价报名
    async baoming(type) {
      console.log("projectInfo", this.projectInfo);
      console.log(JSON.parse(JSON.stringify(this.projectInfo)));
      let params = {
        messageList: [
          {
            //消息类型会话托管
            messageType: type == 6 ? 11 : 5,
            content: type == 6 ? JSON.stringify(this.projectInfo) : "",
          },
        ],
        receiverId: this.projectInfo.identityId,
        senderType: this.identityObj.identityTypeCode,
        //接受消息人类型只有公司认证用户
        receiverType: this.projectInfo.currentIdentity,
        senderId: this.identityObj.identityId,
        sender: this.identityObj.realName,
        receiver: this.projectInfo.crf,
      };
      let res = await SendMessage(params);
      console.log("res", res);
      if (res.code == 0) {
        if (type == 6) {
          this.$message({
            message: "申请已发送",
            type: "success",
          });
        }
        if (type == 5) {
          this.$router.push({
            path: "/home/Chat",
            query: {
              isshow: true, // 你要传递的参数
              data: this.projectInfo,
              num: 5,
            },
          });
        }
        // else{
        //   this.$router.push({
        //     path: "/home/Chat",
        //   });
        // }
      } else {
        this.$message.error(res.data);
      }
    },
    async initPerporty() {
      // 请求详情数据
      let res = await getPropertyType();
      let { status, data } = res;
      if (status) {
        this.proTypes = data;
        this.projectInfoatterType(this.projectInfo.cqlxbh);
      }
    },
    projectInfoatterType(val) {
      let t = this.proTypes.filter((item) => {
        return item.bh == val;
      })[0].lx;
      this.type = t;
    },
    goback() {
      if (sessionStorage.getItem("fromZnxx")) {
        sessionStorage.removeItem("fromZnxx");
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },
    lzlx(type) {
      let res = getLzlx(type);
      return res;
    },
    cqlx(type) {
      console.log("type",type);
      let list = JSON.parse(localStorage.getItem("types"));
      let res = "";
      console.log(list);
      if (list && list.length > 0) {
        // list.forEach((x) => {
        //   console.log("x",x);
        //   if (x.bh == type) {
        //     res = x.name;
        //   }
        // });
        let res = getCqlx(type, list);
        console.log(res);
        return res;
      } else {
        return "";
      }
    },
    async initData() {
      // 请求详情数据
      let params = {
        xmbh: this.$route.query.c,
      };
      //这里是拿到的所有的数据
      let res = await getXmxxDetail(params);
      // console.log("-----------------",res.data.cqlxbh);
      let { status, data } = res;
      // console.log("wxf",data.cqlxbh);
      // // data.cqlxbh=this.cqlx(data.cqlxbh)
      // console.log("data.cqlxbh",data.cqlxbh);
      if (status == 200) {
        this.projectInfo = data;
    
 

        // console.log(this.projectInfo,'这些是全部的数据嘛')
       
        if (this.projectInfo.cqzsList && this.projectInfo.cqzsList.length > 0) {
          this.projectInfo.cqzsList = this.projectInfo.cqzsList.map((x) => {
            return JSON.parse(x);
          });
        }
        if (this.projectInfo.pgjzList && this.projectInfo.pgjzList.length > 0) {
          this.projectInfo.pgjzList = this.projectInfo.pgjzList.map((x) => {
            return JSON.parse(x);
          });
        }
        if (this.projectInfo.ghtjList && this.projectInfo.ghtjList.length > 0) {
          this.projectInfo.ghtjList = this.projectInfo.ghtjList.map((x) => {
            return JSON.parse(x);
          });
        }
        if (
          this.projectInfo.zlzzmList &&
          this.projectInfo.zlzzmList.length > 0
        ) {
          this.projectInfo.zlzzmList = this.projectInfo.zlzzmList.map((x) => {
            return JSON.parse(x);
          });
        }
        this.initPerporty();
        if (this.access_token) {
          // 如果已登录请求一次是否报名接口，如果已报名不显示报名按钮
          this.check(this.projectInfo.xmbh);
        }
        //  规范7
      }
      // this.projectInfo.cqlxbh = this.cqlx(data.cqlxbh)
      console.log("this.projectInfo.cqlxbh",this.projectInfo.cqlxbh);
    },
    //从这里获取异步的
    async getButtonStatus() {
      let params = {
        xmbh: this.$route.query.c,
        authId: this.identityObj.identityId ? this.identityObj.identityId : "",
        currentIdentity: this.identityObj.identityTypeCode
          ? this.identityObj.identityTypeCode
          : "",
        userId: this.identityObj.userId ? this.identityObj.userId : "",
      };

      let res = await getButtonStatus(params);
      let { status, data, message } = res;
      if (status == 200) {
        this.buttonStatus = data;
        // console.log(this.buttonStatus.type, "这是this.buttonStatus");
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.getLandInfo();
    },
    async getImage(path) {
      // 请求图片
      let params = {
        filepath: encodeURI(path),
      };
      let res = await getImageUrl(params);
      this.imgSrc = res;
      window.open(this.imgSrc, "_blank");
    },
    joinProject() {
      // 立即报名
      let token = getStore({ name: "access_token" });
      if (token) {
        this.registration = true;
        // 报名
        // this.getCerStatus().then(() => {
        // 	if (this.status == 6) {
        // 		this.$message.info('您未进行实名认证，不能报名！请先完成实名认证！')
        // 		this.$router.push({ name: 'smrz' })
        // 	} else if (this.status == 0 || this.status == 3) {
        // 		this.$message.info('实名认证正在审核中，请等待！')
        // 	} else {
        // 进行调用看看是否能刷新type值
        this.getButtonStatus();
        // 	}
        // })
        // this.check(this.projectInfo.xmbh).then(() => {
        // 	if (!this.isJoin) {
        this.dialogVisible = true;
        // 	} else {
        // 		this.$message.error('您已报名该项目！')
        // 		this.$router.push({ name: 'ybmxm' })
        // 	}
        // })
      } else {
        this.$confirm("您需要登陆才可以报名，是否跳转到登陆页?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.setItem("backPath", this.$route.fullPath);
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
        this.registration = false;
      }
    },
    uploadJfpz() {
      this.visible = true;
      this.pzprojectInfo.xmbh = this.projectInfo.xmbh;
    },
    beforeUpload(file) {
      // 文件上传之前的函数
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("上传图片为 JPG 或 PNG 格式!");
        this.isUpload = false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        this.isUpload = false;
      }
      return isJPG && isLt2M;
    },
    async upload(file, filelist) {
      if (filelist.length > 0) {
        this.noneBtnImg = true;
      }
      let res = await this.uploadFile(file, filelist);
    },
    async uploadFile(file, filelist) {
      this.picLoading = true;
      // let param = new projectInfoData();
      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", filelist[0].raw);
      let res = await uploadFile(param);
      this.picLoading = false;
      if (res.code == 0) {
        this.pzprojectInfo.bzjFileUrl = res.data.url;
        this.pzprojectInfo.showUrl =
          window.globalUrl.HOME_API +
          "admin/file/get?ossFilePath=" +
          res.data.url;
        // this.$message.success(res.msg);
        this.$message.success(res.msg);
        this.$nextTick(() => {
          this.initData();
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    removeXczp(file, fileList) {
      this.fileList1 = fileList;
      this.pzprojectInfo.bzjFileUrl = null;
      this.noneBtnImg = false;
    },
    //上传保证金凭证
    async submitPz() {
      if (!this.pzprojectInfo.bzjFileUrl) {
        this.$message.warning("请上传保证金缴费凭证！");
        return;
      }
      let res = await savePzxx(this.pzprojectInfo);
      console.log("上传保证金", res);
      let { status, message } = res;

      if (status == 200) {
        this.$message.success(message);
        // this.$message.success(res.msg)

        //重新刷新按钮的type
        this.getButtonStatus();
      } else {
        this.$message.error(message);
      }
      this.visible = false;
      console.log(1111111111);
    },
    async bidding() {
      let token = getStore({ name: "access_token" });
      console.log("token", token);
      if (token) {
        // this.registration = true;
        //   // 竞价
        let res = await getXmxxDetail({ xmbh: this.projectInfo.xmbh });
        let { status, data } = res;
        if (status == 200) {
          console.log("data", data);
          this.currentBid = data;
        }
        this.biddingVisible = true;
      } else {
        this.$confirm("您需要登陆才可以竞价，是否跳转到登陆页?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.setItem("backPath", this.$route.fullPath);
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
        this.registration = false;
      }
    },
    async submitBidding(val, offer) {
      // 竞价信息提交
      let total = 0;
      if (offer == 0 || !offer) {
        total = parseFloat(val) + parseFloat(this.currentBid.gpjg);
      } else {
        total = parseFloat(val) + parseFloat(this.currentBid.dqbj);
      }
      let params = {
        xmbh: this.currentBid.xmbh,
        amount: total,
      };

      // console.log(params,'问题是否出在这')

      let res = await saveBidding(params);
      let { status, rel, message } = res;
      if (status == 200 && rel) {
        this.$message.success(message);
        this.biddingVisible = false;
      } else {
        this.$message.error(message);
      }
    },
    async check(code) {
      let params = {
        xmbh: code,
      };
      let res = await checkJoinPro(params);
      let { status, data, msg } = res;
      if (status) {
        this.isJoin = data == "1"; // 已报名
      }
    },
    async getCerStatus() {
      let res = await getCertificateStatus(this.userInfo.username);
      let { code, data } = res;
      if (code == 0) {
        this.status = data;
      }
    },
    dialogClose() {
      // 关闭弹窗
      this.dialogVisible = false;
    },
    async submit() {
      // 提交报名
      // let info = {
      // 	xmbh: this.projectInfo.xmbh,
      // 	yhid: this.userInfo.id,
      // }
      let params = {
        xmbh: this.projectInfo.xmbh,
      };
      let res = await saveJjbm(params);
      let { status, data, message, rel } = res;
      console.log(rel);
      if (rel == false && status == 200) {
        this.$message.warning(message);
      } else if (status == 200 && rel == true) {
        this.$message.success(message);
        //刷新报名按钮
        this.getButtonStatus();
        let index = localStorage.getItem("eIndex");
        if (index) {
          this.$router.push({ name: "ybmxm" });
        }
      } else {
        this.$message.error(message);
      }
    },
    async download(data) {
      let res = await download({ ossFilePath: data.url });
      if (res) {
        let blob = res;
        const fileName = data.name;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  .base-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 41px 74px 0 74px;
    position: relative;
    .carousel {
      width: 320px;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gallery-top {
      height: 200px;
    }
    .gallery-thumbs {
      margin-top: 20px;
      .swiper-slide {
        width: 100px;
        height: 75px;
      }
    }
    .detail {
      margin-left: 28px;
    }
    p {
      margin: 0;
      line-height: 34px;
    }
    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 22px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    .apply-status {
      text-align: right;
      margin-left: 100px;
      align-self: flex-end;
      .el-button {
        margin-bottom: 20px;
        width: 127px;
        height: 37px;
        padding: 0 12px;
      }
    }
    .goback {
      position: absolute;
      right: 74px;
      top: 50px;
      color: #00a0e9;
      cursor: pointer;
    }
  }
  .table-con {
    margin-top: 20px;
    padding: 18px;
    .table-title {
      height: 54px;
      line-height: 54px;
      border: solid 1px #c9c9c9;
      border-top: 4px solid #18d531;
      padding-left: 19px;
    }
    .table-bottom {
      padding: 18px 30px;
      border: solid 1px #c9c9c9;
      border-top: none;
      display: flex;
      flex-flow: column;
      gap: 20px;
      > span {
        display: block;
      }
      > button {
        width: 200px;
        margin: 0 auto;
      }
    }
    .define-table {
      width: 100%;
      table {
        width: 100%;
        text-align: center;
        border: 1px solid #c9c9c9;
        border-bottom: none;
        tr {
          min-height: 40px;
          height: 40px;
          width: 100%;
          td {
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
            border-bottom: 1px solid #c9c9c9;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .value {
          width: 300px;
          border-right: none;
        }
        .name {
          width: 270px;
          border-right: none;
        }
        .name:first-child {
          border-left: none;
        }

        .special {
          width: 885px;
          height: 44px;
          border-left: 1px solid #c9c9c9;
          border-right: 1px solid #c9c9c9;
          .special-td {
            width: 410px;
            height: 36px;
            line-height: 36px;
            span {
              display: inline-block;
              width: 300px;
              line-height: 18px;
              padding-top: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .special-td-2 {
            width: 475px;
            height: 36px;
            line-height: 36px;
            span {
              display: inline-block;
              width: 300px;
              padding-top: 10px;
              line-height: 18px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
.download-btn {
  cursor: pointer;
}
.img {
  display: inline-block;
  max-height: 320px;
}
</style>

<style lang="less">
.carousel {
  .el-carousel__container {
    height: 100%;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
